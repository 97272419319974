.bs-table-overlay {
    position: relative;
  .loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    // opacity: 0;
    display: none;
    top: 50px;
    left: 0;
    z-index: 100;
    &.active {
      display: block;
    }
  }
}
