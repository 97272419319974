.home-wrapper {
  /* padding-top: 3.25rem; */
  /* padding-left: 150px; */
  height: inherit;
  .side-navigation {
    .region-selection-btn {
      padding: 0;
      text-align: left;

      &::after {
        position: absolute;
        right: 0;
        top: 25%;
      }
    }
    .flags-img {
      width: 1.409rem;
      height: 1.409rem;
      margin-right: 0.789rem !important;
    }

    .dropdown-item{
        padding-bottom: 1rem;
    }

    .secondary-menu{

      border-bottom: solid 1px rgba(112, 112, 112, 0.08);

      .nav-item .nav-link{
        padding-top: 0;
        padding-left: 2rem;
        color: #000;

        &.active {
          color: #00355f;
        }
        &:hover {
          color: rgba(0, 53, 95, 0.9);
        }
      }
      .side-navigation-nav-link{
        border-bottom: none;
      }

    }
  }

  // .sidebar-wrapper {
  //   .region-selection-btn {
  //     padding-left: 0.7rem !important;
  //     padding-right: 0rem !important;
  //     margin-bottom: 1.5rem !important;
  //   }

  //   .region-selection {
  //     position: relative;
  //     z-index: 100 !important;
  //   }
}
