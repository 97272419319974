
html, body, #root {
  height: 100%;
  font-family: "Poppins", sans-serif !important;
  font-size: calc(100vw * 0.00833)!important;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f4f7fc !important; */
  background-color: #f4f4f4 !important;
  
}

.dropdown-toggle::after {
  vertical-align: 0.155em !important;
}

.action-btn-container .action-btn {
  display: inline-block;
  cursor: pointer;
  width: 1.9rem;
  height: 1.9rem;
  text-align: center;
  overflow: hidden;
  transition: all 0.3s ease-out;
  white-space: nowrap;
}

.action-btn-container .action-btn:hover {
  width: 10rem;
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

/* .nav-pills .nav-link.active, .show>.nav-pills .nav-link {
  color: #00aeff !important;
  background-color: #0000 !important;
  font-weight: bold !important;
} */

.ReactVirtualized__Table__row {
  padding: 0.75rem !important;
}

.cursor-pointer-hover:hover {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}


:focus {
  outline: transparent !important;
}

.intl-tel-input .country-list {
  z-index: 3 !important;
}


.drawer {
  box-shadow: 0 .5rem 2rem -17px rgba(0, 0, 0, .11) !important
}

/* .side-navigation-nav-link.active {
  border: 0px solid #0000 !important;
} */



.badge-green {
  color: var(--green) !important;
}

.badge-red {
  color: var(--red) !important;
}



::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #fbfbfb;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #c0c0c0;
}

/* To fix the mobile number input validation status, prioritize is-valid is-invalid classes instead :valid :invalid pseudo code */

.form-control.is-invalid{
  border-color: #dd3444!important;
  
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dd3444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dd3444' stroke='none'/%3e%3c/svg%3e")!important;
}

.form-control.is-invalid:focus{
  border-color: #dd3444!important;
    box-shadow: 0 0 0 0.2rem rgb(221 52 68 / 25%)!important;
}

/* To disable the dropdown caret */
.caret-off::before {
  display: none!important;
}
.caret-off::after {
  display: none!important;
}